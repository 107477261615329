

















































































import Vue from 'vue';
import { getModule } from 'vuex-module-decorators';
import { Component, Watch } from 'vue-property-decorator';
import LoginStore from '@/store/modules/login';

@Component
export default class Sidebar extends Vue {
  LoginStore = getModule(LoginStore, this.$store);

  drawer = true;

  index = 0;

  mini = false;

  userLogged = false;

  userIsAdmin = false;

  items = [
    {
      title: 'Produkte',
      icon: 'mdi-information',
      href: 'https://zebra-invest.de/produkte/',
      target: '_blank',
    },
    { title: 'Lieferungen', icon: 'mdi-truck-delivery', to: 'delivery-managment' },
    // { title: 'Sonderkontingent', icon: 'mdi-decagram', to: 'special-contingent' },
    { title: 'Profil & Adresse', icon: 'mdi-account', to: 'profile' },
    {
      title: 'Hilfecenter',
      icon: 'mdi-help-box',
      href: 'https://zebra-invest.de/hilfecenter/',
      target: '_blank',
    },
    {
      title: 'Kontakt',
      icon: 'mdi-card-account-details',
      href: 'https://zebra-invest.de/kontakt/',
      target: '_blank',
    },
  ];

  itemsAdmin = [
    { title: 'Bestellungen', icon: 'mdi-order-bool-descending-variant', to: 'delivery-overview' },
    { title: 'Liefermengen', icon: 'mdi-truck-delivery', to: 'order-overview' },
    { title: 'User', icon: 'mdi-account-group', to: 'users' },
  ];

  itemsLoggedOut = [
    {
      title: 'Produkte',
      icon: 'mdi-information',
      href: 'https://zebra-invest.de/produkte/',
      target: '_blank',
    },
    {
      title: 'Hilfecenter',
      icon: 'mdi-help-box',
      href: 'https://zebra-invest.de/hilfecenter/',
      target: '_blank',
    },
    {
      title: 'Kontakt',
      icon: 'mdi-card-account-details',
      href: 'https://zebra-invest.de/kontakt/',
      target: '_blank',
    },
  ];

  created(): void {
    this.mini = this.$vuetify.breakpoint.lgAndDown;
  }

  get itemsForMenu() {
    if (this.userLogged && !this.userIsAdmin) {
      return this.items;
    }
    if (this.userIsAdmin && this.userLogged) {
      return this.itemsAdmin;
    }
    return this.itemsLoggedOut;
  }

  beforeMount() {
    if (window.localStorage.getItem('JWT')) {
      this.userLogged = true;
      this.LoginStore.userStatusChangeLoggedIn();
    }

    const isAdmin = window.localStorage.getItem('isAdmin');
    if (isAdmin === 'true') {
      this.userIsAdmin = true;
      this.LoginStore.userStatusChangeAdmin();
    }
  }

  // eslint-disable-next-line class-methods-use-this
  logOut() {
    this.LoginStore.userStatusChangeLoggedOut();
    window.localStorage.clear();
    this.$router.push('/login');
  }

  checkUser() {
    if (this.userLogged && this.userIsAdmin) {
      this.$router.push('/users');
    }
    if (this.userLogged && !this.userIsAdmin) {
      this.$router.push('/profile');
    }
    if (!this.userLogged) {
      this.$router.push('/login');
    }
  }

  @Watch('userLogged')
  onUserLoggedChange() {
    this.index += 1;
  }

  @Watch('userIsAdmin')
  onuserIsAdminChange() {
    this.index += 1;
  }

  @Watch('$store.state.login.isLogged')
  LoggedStatus() {
    this.userLogged = this.$store.state.login.isLogged;
  }

  @Watch('$store.state.login.isAdmin')
  LoggedAdminStatus() {
    this.userIsAdmin = this.$store.state.login.isAdmin;
  }
}
