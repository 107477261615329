import Vue from 'vue';
import Vuex from 'vuex';
import NotificationStore from './modules/notification';
import LoginStore from './modules/login';

Vue.use(Vuex);

const getConfig = () => {
  return {
    modules: {
      notification: NotificationStore,
      login: LoginStore,
    },
  };
};

export const initialState = getConfig();

const store = new Vuex.Store(getConfig());
export default store;
