import { Module, VuexModule, Mutation } from 'vuex-module-decorators';

export interface NotificationState {
  isError: boolean;
  message: string;
  clearable: boolean;
  statusCode: number;
  show?: boolean;
  timestamp: number;
}

export const ERRORS = {
  AUTHORIZATION: `Sie haben keinen Zugriff auf diese Site, wenden Sie sich bitte an Ihren Administrator`,
  SERVER: 'Server Error.',
  DATA: 'There is a problem with the data.',
  EXPIRED_TOKEN: 'Precondition Failed',
};

export const getNotificationMessage = (notificationSnackbar: NotificationState) => {
  const { statusCode, message } = notificationSnackbar;
  if (statusCode === 401 || statusCode === 403) {
    return ERRORS.AUTHORIZATION;
  }
  if (statusCode >= 500 && statusCode < 600) {
    return ERRORS.SERVER;
  }
  if (statusCode === 400) {
    return ERRORS.DATA;
  }
  if (statusCode === 412) {
    return `${ERRORS.EXPIRED_TOKEN} ${message}`;
  }
  return message || '';
};

@Module({ name: 'notification', namespaced: true })
class NotificationStore extends VuexModule implements NotificationState {
  isError = false;

  message = '';

  statusCode = 0;

  clearable = false;

  show = false;

  timestamp = 0;

  @Mutation
  showNotificationSnackbar(value: NotificationState) {
    this.timestamp = value.timestamp;
    this.statusCode = value.statusCode;
    this.isError = value.isError || false;
    this.clearable = value.clearable || false;
    const message = getNotificationMessage(value);
    this.message = message;
    this.show = message !== '';
  }
}

export default NotificationStore;
