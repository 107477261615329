export const getToken = () => {
  const token = window.localStorage.getItem('JWT') || '';
  return token;
};

export const setToken = (token: string): void => {
  window.localStorage.setItem('JWT', token);
};

export const setLogedUserInfo = (userId: number, isAdmin: boolean) => {
  window.localStorage.setItem('userId', `${userId}`);
  window.localStorage.setItem('isAdmin', `${isAdmin}`);
};

export const setDummyPass = (hasDummyPass: boolean) => {
  window.localStorage.setItem('hasDummyPass', `${hasDummyPass}`);
};

export const setDummyEmail = (hasDummyEmail: boolean) => {
  window.localStorage.setItem('hasDummyEmail', `${hasDummyEmail}`);
};

export const getIsAdmin = (): boolean => {
  const isAdmin = window.localStorage.getItem('isAdmin');
  if (isAdmin !== null) {
    const localAdmin = isAdmin === 'true';
    return localAdmin;
  }
  return false;
};

export const getUserId = () => {
  const userId = window.localStorage.getItem('userId');
  return userId;
};

export const getDummyPass = (): boolean => {
  const hasDummyPass = window.localStorage.getItem('hasDummyPass');
  if (hasDummyPass) {
    return hasDummyPass === 'true';
  }
  return false;
};

export const getDummyEmail = (): boolean => {
  const hasDummyPass = window.localStorage.getItem('hasDummyEmail');
  if (hasDummyPass) {
    return hasDummyPass === 'true';
  }
  return false;
};
