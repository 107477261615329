/* eslint-disable camelcase */
/* eslint-disable  no-underscore-dangle, no-console */
import Vue from 'vue';
import { ValidationProvider, ValidationObserver, extend } from 'vee-validate';
import { required, email, max, min, min_value } from 'vee-validate/dist/rules';

extend('is_true', {
  validate(value) {
    if (value === true) {
      return true;
    }
    return `Bitte bestätigen Sie, dass ein Infektionsgeschehen vorliegt, indem Sie die checkbox aktivieren.`;
  },
});

extend('stadt', {
  validate(value) {
    if (value === true) {
      return true;
    }
    return '';
  },
});

extend('min_value', {
  ...min_value,
  message: 'Werte können nicht null sein',
});
extend('greater_than_zero', {
  validate(value) {
    if (value > 0) {
      return true;
    }
    return 'Der Wert muss größer als Null sein';
  },
});

extend('maxLength', {
  validate(value) {
    if (value.length > 200) {
      return 'Sie haben die Zeichenanzahl von überschritten';
    }
    return true;
  },
});

extend('max', { ...max, message: 'Fehlender Wert' });

extend('min', { ...min, message: 'Falscher Wert' });

extend('required', {
  ...required,
  message: 'Dies ist ein Pflichtfeld',
});

extend('email', {
  ...email,
  message: 'Dieses Feld muss eine gültige E-Mail-Adresse sein',
});

// Global component registration
Vue.component('ValidationProvider', ValidationProvider);
Vue.component('ValidationObserver', ValidationObserver);
