import { Module, VuexModule, Mutation } from 'vuex-module-decorators';

export interface LoginState {
  isLogged: boolean;
  isAdmin: boolean;
}

@Module({ name: 'login', namespaced: true })
class LoginStore extends VuexModule implements LoginState {
  isLogged = false;

  isAdmin = false;

  @Mutation
  userStatusChangeLoggedIn() {
    this.isLogged = true;
  }

  @Mutation
  userStatusChangeLoggedOut() {
    this.isLogged = false;
    this.isAdmin = false;
  }

  @Mutation
  userStatusChangeAdmin() {
    this.isAdmin = true;
  }
}

export default LoginStore;
