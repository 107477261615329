

















import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import { getModule } from 'vuex-module-decorators';
import NotificationStore from '@/store/modules/notification';

@Component
class NotificationSnackbar extends Vue {
  notificationStore = getModule(NotificationStore, this.$store);

  notificationMessage = '';

  get notification() {
    const { message, isError, show, clearable, timestamp } = this.notificationStore;
    this.notificationMessage = message;
    return { message, isError, show, clearable, timestamp };
  }

  onHideNotification() {
    this.notificationStore.showNotificationSnackbar({
      isError: false,
      message: '',
      statusCode: 0,
      clearable: false,
      timestamp: 0,
    });
  }
}
export default NotificationSnackbar;
