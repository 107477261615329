














import { Component, Vue } from 'vue-property-decorator';
import Sidebar from '@/components/side-bar/side-bar.vue';
import NotificationSnackbar from '@/common/components/notification-snackbar/_notification-snackbar.vue';
import '@/styles.scss';

@Component({
  components: {
    Sidebar,
    NotificationSnackbar,
  },
})
export default class App extends Vue {}
