import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
import {
  getToken,
  getUserId,
  getIsAdmin,
  getDummyEmail,
  getDummyPass,
} from '@/common/functions/auth';

Vue.use(VueRouter);

const LOGIN_ROUTES = [
  'login-type',
  'login-form',
  'forgot-password',
  'forgot-password-confirmation',
];

const DEFAULT_CREDENTIALS_ROUTES = [...LOGIN_ROUTES, 'default-credentials'];

const ADMIN_ROUTES = [
  'default-credentials',
  'profile',
  'delivery-managment',
  'users',
  'order-overview',
  'delivery-overview',
];

const USER_ROUTES = [
  'default-credentials',
  'profile',
  'delivery-managment',
  'special-contingent',
  'produkt',
  'hilfecenter',
  'kontakt',
];

const routes: Array<RouteConfig> = [
  {
    path: '/login',
    component: () => import('../views/login.vue'),
    children: [
      {
        name: 'login-form',
        path: ':type',
        component: () => import('../components/login/login-form.vue'),
      },
      {
        name: 'login-type',
        path: '',
        component: () => import('../components/login/login-options.vue'),
      },
    ],
  },
  {
    path: '/forgot-password',
    component: () => import('../views/forgot-password.vue'),
    children: [
      {
        path: '',
        name: 'forgot-password',
        component: () => import('../components/forgot-password/forgot-password-form.vue'),
      },
      {
        path: 'confirmation',
        name: 'forgot-password-confirmation',
        component: () => import('../components/forgot-password/forgot-password-confirmation.vue'),
      },
    ],
  },
  {
    path: '/default-credentials',
    name: 'default-credentials',
    component: () => import('../views/change-dummy-credentials.vue'),
  },
  {
    path: '/profile',
    name: 'profile',
    component: () => import('../views/user-views/profile.vue'),
  },
  {
    path: '/delivery-managment',
    name: 'delivery-managment',
    component: () => import('../views/user-views/delivery-managment.vue'),
  },
  // {
  //   path: '/special-contingent',
  //   name: 'special-contingent',
  //   component: () => import('../views/user-views/special-contingent.vue'),
  // },
  {
    path: '/users',
    name: 'users',
    component: () => import('../views/admin-views/users-admin.vue'),
  },
  {
    path: '/order-overview',
    name: 'order-overview',
    component: () => import('../views/admin-views/order-admin.vue'),
  },
  {
    path: '/delivery-overview',
    name: 'delivery-overview',
    component: () => import('../views/admin-views/delivery-admin.vue'),
  },
  {
    path: '/produkt',
    name: 'produkt',
    beforeEnter() {
      window.open('https://zebra-invest.de/produkte/', '_blank');
    },
  },
  {
    path: '/hilfecenter',
    name: 'hilfecenter',
    beforeEnter() {
      window.open('https://zebra-invest.de/hilfecenter', '_blank');
    },
  },
  {
    path: '/kontakt',
    name: 'kontakt',
    beforeEnter() {
      window.open('http://www.zebra-invest.de/hilfe-selbsttest', '_blank');
    },
  },
  {
    path: '*',
    redirect: 'profile',
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

// Redirect to login if session does not exist
router.beforeEach((to, from, next) => {
  const routeName = to.name || '';
  if (LOGIN_ROUTES.includes(routeName)) next();
  else {
    const token = getToken();
    const userId = getUserId();
    const isAdmin = getIsAdmin();
    if (token === '' || userId === null || isAdmin === null) {
      window.localStorage.clear();
      next({ name: 'login-type' });
    } else {
      next();
    }
  }
});

// Redirect to default credentials
router.beforeEach((to, from, next) => {
  const routeName = to.name || '';
  if (DEFAULT_CREDENTIALS_ROUTES.includes(routeName)) next();
  else {
    const hasDummyEmail = getDummyEmail();
    const hasDummyPass = getDummyPass();
    if (hasDummyEmail || hasDummyPass) {
      next({ name: 'default-credentials' });
    } else {
      next();
    }
  }
});

// Admin and no admin validations
router.beforeEach((to, from, next) => {
  const routeName = to.name || '';
  const isAdmin = getIsAdmin();

  if (LOGIN_ROUTES.includes(routeName)) next();
  else if (isAdmin && ADMIN_ROUTES.includes(routeName)) next();
  else if (!isAdmin && USER_ROUTES.includes(routeName)) next();
  else if (isAdmin) {
    next({ name: 'users' });
  } else {
    next({ name: 'profile' });
  }
});

// User is not allowed to go to login routes if session exist
router.beforeEach((to, from, next) => {
  const routeName = to.name || '';

  const token = getToken();
  const userId = getUserId();
  const isAdmin = getIsAdmin();

  if (LOGIN_ROUTES.includes(routeName)) {
    if (token === '' || userId === null || isAdmin === null) {
      next();
    } else if (userId && isAdmin) {
      next('/delivery-overview');
    } else if (userId && !isAdmin) {
      next('/profile');
    }
  } else {
    next();
  }
});

export default router;
