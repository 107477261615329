import Vue from 'vue';

// FormatNumber
const fn = (value: number): string => {
  if (value < 10) {
    return `0${value}`;
  }
  return `${value}`;
};

Vue.filter('dateFormat', (date: Date) => {
  if (!date) return '';
  return `${fn(date.getDate())}.${fn(date.getMonth() + 1)}.${date.getFullYear()}`;
});

Vue.filter('dateFormatString', (value: string) => {
  if (!value) return '';
  const date = value.split('-');
  if (date.length !== 3) return '';
  return `${date[2]}.${date[1]}.${date[0]}`;
});

Vue.filter('dateFormatRange', (dates: Date[]) => {
  if (dates.length !== 2) return '';

  const fDate = dates[0];
  const fDateString = `${fn(fDate.getDate())}.${fn(fDate.getMonth() + 1)}.${fDate.getFullYear()}`;
  const lDate = dates[1];
  const lDateString = `${fn(lDate.getDate())}.${fn(lDate.getMonth() + 1)}.${lDate.getFullYear()}`;

  return `${fDateString} - ${lDateString}`;
});

Vue.filter('dateFormatRangeString', (value: string[]) => {
  if (!value) return '';
  let newValue = value;
  const firstDate = new Date(newValue[0]);
  const secondDate = new Date(newValue[1]);
  if (firstDate.getTime() > secondDate.getTime()) {
    const firstPosition = newValue[1];
    const secondPosition = newValue[0];
    newValue = [];
    newValue.push(firstPosition);
    newValue.push(secondPosition);
  }
  const dates: string[] = [];
  if (newValue[0]) {
    const date1 = new Date(newValue[0]);
    dates[0] = `${fn(date1.getDate())}-${fn(date1.getMonth() + 1)}-${date1.getFullYear()}`;
  }
  if (newValue[1]) {
    const date2 = new Date(newValue[1]);
    dates[1] = `${fn(date2.getDate())}-${fn(date2.getMonth() + 1)}-${date2.getFullYear()}`;
  }
  return dates;
});
